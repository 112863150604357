<template>
  <remote-page />
</template>

<script>
  import RemotePage from '@peynman/press-vue-admin/pages/RemotePage.vue'

  export default {
    name: 'ModenaHome',
    components: {
      RemotePage,
    },
  }
</script>
